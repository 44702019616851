import addHours from 'date-fns/addHours';
import format from 'date-fns/format';
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes';

export const formatToDatetime = (date) => format(date, "yyyy-MM-dd'T'HH:mm");

export const getMinReserveDatetime = () =>
  addHours(
    roundToNearestMinutes(new Date(), {
      nearestTo: 30,
      //   roundingMethod: 'ceil',
    }),
    5
  );

export const getEarlierDate = (firstDate, secondDate) => {
  return new Date(Math.min(firstDate, secondDate));
};

export const getLaterDate = (firstDate, secondDate) => {
  return new Date(Math.max(firstDate, secondDate));
};
