const coordinates = [
  {
    id: 'HI1',
    latitude: 14.571326,
    longitude: 121.066858,
  },
  {
    id: 'PA1',
    latitude: 14.571476,
    longitude: 121.067291,
  },
  {
    id: 'PA5',
    latitude: 14.571414,
    longitude: 121.067484,
  },
  // Canley
  // using Suites coords for now but we can use
  // 14.571525959869339, 121.06840775371911
  // which is for Kim King's Kitchen
  {
    id: 'PA6',
    latitude: 14.571414,
    longitude: 121.067484,
  },
  {
    id: 'NE1',
    latitude: 14.65853625368691,
    longitude: 120.99665306507227,
  },
  {
    id: 'SF1',
    latitude: 15.073511439038484,
    longitude: 120.64473476633582,
  },
  {
    id: 'LP1',
    latitude: 14.443033,
    longitude: 121.000766,
  },
  {
    id: 'MA1',
    latitude: 14.668034,
    longitude: 120.981932,
  },
];

export default coordinates;
