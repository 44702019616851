import RoomCardSkeleton from './RoomCardSkeleton';
import { multiply } from 'utils/misc';

const RoomsSkeleton = ({ n }) => {
  return (
    <div className='d-flex overflow-hidden'>
      {multiply(n, (i) => (
        <RoomCardSkeleton key={i} />
      ))}
    </div>
  );
};

export default RoomsSkeleton;
