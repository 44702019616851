import { cleanLocalesData } from 'utils/fetchLocales';

const locales = [
  {
    id: 8,
    code: '10',
    company_id: '1',
    branch: 'Pasig',
    branch_code: 'PG',
    address: '207 HILLCREST DRIVE, BAGONG ILOG PASIG CITY',
    telephone: '671-9773 to 77/ 671-9782 to 88',
    receipt_header: 'PASIG TOURIST DEVELOPMENT CORPORATION',
  },
  {
    id: 1,
    code: '08',
    company_id: '1',
    branch: 'Hillcrest',
    branch_code: 'HI',
    address: '207 HILLCREST DRIVE, BAGONG ILOG PASIG CITY',
    telephone: '671-9773 to 77',
    receipt_header: 'PASIG TOURIST DEVELOPMENT CORPORATION',
  },
  {
    id: 2,
    code: '01',
    company_id: '1',
    branch: 'Panorama',
    branch_code: 'PA',
    address: 'ESCARPMENT ROAD, BARANGAY BAGONG ILOG, PASIG CITY',
    telephone: '671-9782 to 88',
    receipt_header: 'PANORAMA ENTERPRISES INC',
  },
  {
    id: 3,
    code: '10',
    company_id: '1',
    branch: 'Malabon',
    branch_code: 'MA',
    address: '197 MAC ARTHUR HI-WAY POTRERO MALABON CITY',
    telephone: '361-3361 to 63',
    receipt_header: 'GALACTIC SPACE DEVT CORP',
  },
  {
    id: 4,
    code: '02',
    company_id: '1',
    branch: 'Las Piñas',
    branch_code: 'LP',
    address: 'VINTA LODGE ADMIRAL ROAD CITY OF LAS PINAS NCR, FOURTH DISTRICT',
    telephone: '800-6190 to 94',
    receipt_header: "SOUTH LAS PINAS TOURIST DEV'T CORP.",
  },
  {
    id: 5,
    code: '06',
    company_id: '1',
    branch: 'North Edsa',
    branch_code: 'NE',
    address: '3 DON VICENTE ANG ST CALOOCAN CITY NCR, THIRD DISTRICT',
    telephone: '363-7051 to 53',
    receipt_header: 'CALOOCAN TOURIST DEVT CORP',
  },
  {
    id: 7,
    code: '09',
    company_id: '1',
    branch: 'San Fernando',
    branch_code: 'SF',
    address: 'Km 73.7 Post Sindalan Mac Arthur Highway, San Fernando, Pampanga',
    telephone: '(045) 961-5387/961-3785',
    receipt_header: "San Fernando Tourist Dev't Corp.",
  },
];

export default cleanLocalesData(locales);
