const RoomCardSkeleton = () => {
  return (
    <div
      className='roomCard placeholder-wave border-red bg-white ml-20'
      data-aos='fade'
      data-aos-delay={20}
      style={{ width: 300 }}
    >
      <div
        className='placeholder bg-secondary text-secondary'
        style={{ width: 300, height: 300 }}
      ></div>

      <div className='py-10 px-10'>
        <span className='placeholder bg-secondary text-secondary placeholder-lg col-5'></span>
        <div className='mt-5 lh-1'>
          <span className='placeholder bg-secondary text-secondary placeholder-sm col-12'></span>
          <span className='placeholder bg-secondary text-secondary placeholder-sm col-12'></span>
          <span className='placeholder bg-secondary text-secondary placeholder-sm col-8'></span>
        </div>

        <div className='mt-15 btn btn-dark border-0 placeholder bg-secondary text-secondary disabled col-4'></div>
      </div>
    </div>
  );
};

export default RoomCardSkeleton;
