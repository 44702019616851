import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import {
  AMENITIES,
  ROOM_TYPE_COLORS,
  SUPER_THEMATIC_PARTY_RATES_CONVERSION,
} from 'utils/constants';
import { formatToDatetime } from 'utils/dateUtils';
import { resolveImageLink } from 'utils/misc';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';

// ! metadata is passed instead since this is used in multiple places
const RoomCard = ({
  locale,
  rate,
  date,
  data,
  otherData: { isBookingForParty = false, noOfPax = 2 },
  index,
}) => {
  const branch = locale.toLowerCase();
  const path = `/rooms/${branch}/${data.id}`;
  const query = { rate, date: formatToDatetime(date) };
  const href = { pathname: path, query };
  const isPartySuperThematic =
    isBookingForParty &&
    data.availableForParty &&
    data.main_type === 'thematic' &&
    data.isSuperThematic;
  const rateId = isPartySuperThematic
    ? SUPER_THEMATIC_PARTY_RATES_CONVERSION[rate] ?? rate
    : rate;
  const hasImageGallery = data?.image?.startsWith?.('/img/rooms');
  const linkPrefix = hasImageGallery
    ? process.env.NEXT_PUBLIC_ROOMS_URL
    : process.env.NEXT_PUBLIC_UPLOADS_URL;

  const images = [`${resolveImageLink(data, branch)}`];
  if (hasImageGallery) {
    images.push(
      ...[2, 3, 4, 5].map(
        (n) => `${linkPrefix}/${data.locale}/${data.number}/${n}.jpg`
      )
    );
  }

  return (
    <div
      className='roomCard hover-inside-slider bg-white rounded'
      data-aos='fade'
      data-aos-delay={20 * (index + 1)}
    >
      <Link href={href} as={path}>
        <div className='roomCard__image'>
          <div className='cardImage ratio ratio-1:1'>
            <div className='cardImage__content custom_inside-slider'>
              {images.length > 1 ? (
                <Swiper
                  modules={[Pagination, Navigation, Autoplay]}
                  direction='horizontal'
                  loop={true}
                  autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                    bulletClass: 'swiper-pagination-bullet',
                    bulletActiveClass: 'swiper-pagination-bullet-active',
                  }}
                  navigation={true}
                >
                  {images?.map((link, i) => (
                    <SwiperSlide key={i}>
                      <Image
                        width={300}
                        height={300}
                        className='rounded-4 col-12 js-lazy swiper-card'
                        src={link}
                        alt={`picture of the ${data.type} room, "${data.name}"`}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <Image
                  src={resolveImageLink(data, branch)}
                  width={300}
                  height={300}
                  className='rounded-4 col-12 js-lazy image-card'
                  alt={`picture of the ${data.type} room, "${data.name}"`}
                />
              )}
            </div>
          </div>

          <div className='cardImage__rightBadge'>
            <span className='text-15 text-light-2 lh-16 rounded-left-4 bg-black py-5 px-15 fw-500'>
              ₱ {data.rates[rateId]}
            </span>
          </div>

          <div className='cardImage__leftBadge'>
            <div
              className={clsx(
                'py-5 px-15 rounded-right-4 text-12 lh-16 fw-500 uppercase',
                ROOM_TYPE_COLORS[data.type]
              )}
            >
              {data.type}
            </div>
          </div>
        </div>
      </Link>

      <div className='roomCard__content mt-10 px-10 py-5 d-flex flex-column h-100'>
        <div>
          <Link
            href={href}
            as={path}
            className='d-flex flex-wrap align-items-centers'
          >
            <h4 className='roomCard__title text-dark-1 text-18 lh-16 fw-500 w-100'>
              <span>{data.name.toUpperCase()}</span>
            </h4>
            {isBookingForParty && (
              <span className='text-12 text-light-1'>
                ({data.minPax} to {data.maxPax} pax)
              </span>
            )}
          </Link>

          <p className='roomCard__description h-60 text-light-1 lh-14 text-14 mt-5'>
            {data.description}
          </p>
          <div className='mt-5'>
            <p className='text-dark-1'>Inclusions</p>

            <div
              className='row d-flex flex-wrap px-0 justify-center mt-10'
              style={{ gap: 15 }}
            >
              {AMENITIES.map((item) => (
                <div
                  className='col-2 d-flex flex-column items-center p-0'
                  key={item.id}
                >
                  <Image
                    src={item.img}
                    height={20}
                    width={20}
                    alt={`${item.name} logo`}
                    style={{
                      filter: data.amenities.includes(item.id)
                        ? 'opacity(100%)'
                        : 'opacity(10%)',
                    }}
                  />
                  <span
                    className='text-10 text-light-1'
                    style={{
                      opacity: data.amenities.includes(item.id)
                        ? '100%'
                        : '40%',
                    }}
                  >
                    {item.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className='mt-15 mb-10 fw-500 booking-button'>
          <Link
            href={{
              pathname: '/booking',
              query: {
                locale: branch,
                roomid: data.id,
                rate,
                date: formatToDatetime(date),
                isparty: isBookingForParty,
                pax: noOfPax,
              },
            }}
            as='/booking'
          >
            <button className='h-40 px-24 bg-black text-white rounded w-100'>
              Book now
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RoomCard;
