import { PANORAMA_AREAS } from './constants';
import { fetchAPI, toTitleCase } from './misc';

export const fetchLocales = () => fetchAPI('/branch');

export const cleanLocalesData = (arr) =>
  arr
    .map((item) =>
      item.branch_code === 'PA'
        ? PANORAMA_AREAS.map((area) => ({
            id: item.branch_code + area.id,
            code: item.branch_code,
            area_id: area.id,
            name: area.name,
            address: toTitleCase(item.address).replace('Ncr', 'NCR'),
            telephone: item.telephone,
          }))
        : {
            id: item.branch_code + item.id,
            code: item.branch_code,
            area_id: 1,
            name: item.branch,
            address: toTitleCase(item.address).replace('Ncr', 'NCR'),
            telephone: item.telephone,
          }
    )
    .flat();
