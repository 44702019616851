import { findNearest } from 'geolib';
import md5 from 'crypto-js/md5';
import {
  LOCALES,
  LOCALES_WITH_AREA_ID,
  PARTY_RATE_IDS_CONVERSION,
  PARTY_RATES,
  SUPER_THEMATIC_PARTY_RATES_CONVERSION,
  SUPER_THEMATIC_ROOMS,
} from './constants';
import { formatToDatetime } from './dateUtils';
import coordinates from 'data/coords';

export const body = (o = {}, headers = {}) => ({
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers,
  },
  body: JSON.stringify(o),
});

export const fetchAPI = (path, o = {}) =>
  fetch(
    `${process.env.NEXT_PUBLIC_API_URL}${path}`,
    body({
      ...o,
      company: process.env.NEXT_PUBLIC_COMPANY,
      branch: o?.branch?.toLowerCase?.(),
    })
  ).then((res) => res.json());

export const formatToMoney = (value) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return `₱ ${formatter.format(value)}`;
};

export const getBranchName = (locale, areaId = 1, format = '%l') => {
  const localeName = LOCALES[locale.toUpperCase()];
  const areaName = LOCALES_WITH_AREA_ID[locale.toUpperCase() + areaId];

  return format
    .replace('%l', toTitleCase(localeName))
    .replace('%L', localeName.toUpperCase())
    .replace('%a', toTitleCase(areaName))
    .replace('%A', areaName.toUpperCase());
};

// from https://stackoverflow.com/questions/196972
export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const multiply = (n, fn) => new Array(n).fill().map((value, i) => fn(i));

export const serialize = (o, formatter = encodeURIComponent) => {
  const query = [];
  for (let key in o) {
    const value = o[key];

    if (value != null) {
      query.push(
        `${formatter(key)}=${
          value instanceof Date ? formatToDatetime(value) : formatter(value)
        }`
      );
    }
  }

  return query.join('&');
};

export const getLocaleAndAreaId = (key) => {
  const index = key.length - 1;
  const id = key.substring(0, index).toLowerCase();
  const area = +key.substring(index);

  return [id, area];
};

export const findNearestLocale = (coords) => {
  const nearest = findNearest(coords, coordinates);
  return getLocaleAndAreaId(nearest.id);
};

export const sortAndSieveObject = (obj) => {
  const sortedKeys = Object.keys(obj)
    .filter((key) => obj[key] !== undefined && obj[key] !== null)
    .sort();

  const sortedObject = {};
  for (const key of sortedKeys) {
    sortedObject[key] = obj[key];
  }

  return sortedObject;
};

export const generateSign = (params, signKey) => {
  const sortedParams = { ...sortAndSieveObject(params), key: signKey };
  return md5(serialize(sortedParams, (value) => value.toString()));
};

export const resolveImageLink = (data, branch) => {
  const locale = data.locale ?? branch;

  if (!locale) throw new Error('Locale must be specified');

  return data.image?.startsWith?.('/img/rooms')
    ? `${process.env.NEXT_PUBLIC_ROOMS_URL}/${locale}/${data.number}/1.jpg`
    : `${process.env.NEXT_PUBLIC_UPLOADS_URL}/${locale}/${data.image}`;
};

export const formatMilitaryTime = (militaryTime) => {
  const [hours, minutes] = militaryTime.split(':');
  const hour = parseInt(hours, 10);
  const period = hour >= 12 ? 'PM' : 'AM';
  const hour12 = hour % 12 || 12; // Convert 0 to 12-hour format

  return `${hour12}:${minutes} ${period}`;
};

/**
 * Convert back room_rate's core_id to id
 * @param {string} locale
 * @param {number} rateId the core_id from `room_rate`
 * @param {number?} roomId
 * @returns {number} the id from `room_rate`
 */
export const resolveRate = (locale, rateId, roomId) => {
  const isParty = rateId in PARTY_RATES;

  if (isParty) {
    const partyRates = PARTY_RATE_IDS_CONVERSION[locale];
    const superThematicRooms = SUPER_THEMATIC_ROOMS[locale];

    if (superThematicRooms?.includes?.(roomId)) {
      return partyRates[SUPER_THEMATIC_PARTY_RATES_CONVERSION[rateId]];
    }

    return partyRates[rateId];
  }

  return rateId;
};
